/* ios phone css start here */
.onoffswitch {
	position: relative;
	width: 90px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.list-none {
	padding-left: 0px;

}

.list-none li {
	list-style-type: none;
}

.list-none li a {
	color: #454545;
	padding: 10px 0px;
	font-size: 17px;
	font-weight: bold;
	text-decoration: none;
	border-bottom: 1px solid #ccc;
	display: block;

}

.list-none li a:hover {
	color: #27aae1;
}

.thumbs-img img {
	border: 1px solid #ccc;
	margin-top: -25px;
	margin-left: 15px;
}

.wht-bgs {
	background: #fff;
	padding: 5px;
	text-align: center;
}

.onoffswitch-checkbox {
	display: none;
}

.onoffswitch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 2px solid #999999;
	border-radius: 20px;
}

.onoffswitch-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	-moz-transition: margin 0.3s ease-in 0s;
	-webkit-transition: margin 0.3s ease-in 0s;
	-o-transition: margin 0.3s ease-in 0s;
	transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
	display: block;
	float: left;
	width: 50%;
	height: 30px;
	padding: 0;
	line-height: 30px;
	font-size: 14px;
	color: white;
	font-family: Trebuchet, Arial, sans-serif;
	font-weight: bold;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.onoffswitch-inner:before {
	content: "YES";
	padding-left: 10px;
	background-color: #2FCCFF;
	color: #FFFFFF;
}

.onoffswitch-inner:after {
	content: "NO";
	padding-right: 10px;
	background-color: #EEEEEE;
	color: #999999;
	text-align: right;
}

.onoffswitch-switch {
	display: block;
	width: 18px;
	margin: 6px;
	background: #FFFFFF;
	border: 2px solid #999999;
	border-radius: 20px;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 56px;
	-moz-transition: all 0.3s ease-in 0s;
	-webkit-transition: all 0.3s ease-in 0s;
	-o-transition: all 0.3s ease-in 0s;
	transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
	margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
	right: 0px;
}






.cmn-toggle {
	position: absolute;
	margin-left: -9999px;
	visibility: hidden;
}

.cmn-toggle+label {
	display: block;
	position: relative;
	cursor: pointer;
	outline: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

input.cmn-toggle-round-flat+label {
	padding: 2px;
	width: 75px;
	height: 30px;
	background-color: #dddddd;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	-ms-border-radius: 60px;
	-o-border-radius: 60px;
	border-radius: 60px;
	-webkit-transition: background 0.4s;
	-moz-transition: background 0.4s;
	-o-transition: background 0.4s;
	transition: background 0.4s;
}

input.cmn-toggle-round-flat+label:before,
input.cmn-toggle-round-flat+label:after {
	display: block;
	position: absolute;
	content: "";
}

input.cmn-toggle-round-flat+label:before {
	top: 2px;
	left: 2px;
	bottom: 2px;
	right: 2px;
	background-color: #fff;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	-ms-border-radius: 60px;
	-o-border-radius: 60px;
	border-radius: 60px;
	-webkit-transition: background 0.4s;
	-moz-transition: background 0.4s;
	-o-transition: background 0.4s;
	transition: background 0.4s;
}

input.cmn-toggle-round-flat+label:after {
	top: 4px;
	left: 4px;
	bottom: 4px;
	width: 22px;
	background-color: #dddddd;
	-webkit-border-radius: 52px;
	-moz-border-radius: 52px;
	-ms-border-radius: 52px;
	-o-border-radius: 52px;
	border-radius: 52px;
	-webkit-transition: margin 0.4s, background 0.4s;
	-moz-transition: margin 0.4s, background 0.4s;
	-o-transition: margin 0.4s, background 0.4s;
	transition: margin 0.4s, background 0.4s;
}

input.cmn-toggle-round-flat:checked+label {
	background-color: #52ca02;
}

input.cmn-toggle-round-flat:checked+label:after {
	margin-left: 45px;
	background-color: #52ca02;
}





/* End here */
.top-bar {
	background: #27aae1;
	width: 100%;
	padding: 25px 100px;
}

.counts {
	font-size: 20px;
	text-align: center;
	color: #27aae1;
	display: block;
	text-decoration: none;
}

.headn-h2 {
	color: #fff;
	font-weight: bold;
	text-align: center;
	font-size: 25px;
	margin-top: 0px;
	margin-bottom: 0px;
	text-align: center;
}

.main-section {
	padding: 35px;
	background: #fff;
}

.headn-h5 {
	font-size: 17px;
	color: #454545;
	margin-top: 0px;
	margin-bottom: 20px;
}

.develop {
	width: 100%;
	position: absolute;
	border-top: 1px solid #27aae1;
	width: 100%;
	left: 0px;
	padding: 35px;
	bottom: 0px;
}

.dashboard-bar {
	width: 100%;
	background: #787778;
	padding: 25px 0;
}

* {
	outline: none !important;
}

.headn-h3 {
	font-size: 24px;
	color: #27aae1;
	text-align: center;
	font-weight: bold;
}

.bs-example {}

.spensit {
	position: relative;
	display: block;
	padding: 10px 15px;
	font-size: 18px;
	color: #fff !important;
	text-align: right;
}

.left-section {
	width: 22%;
	float: left;
	background: #f5f6f7;
	padding: 25px 35px;
	height: 100%;
	min-height: 600px;
	position: relative;
}

.right-section {
	width: 78%;
	float: left;
}

/* responsive start here */
@media screen and (max-width:1200px) and (min-width:991px) {
	.top-bar {
		padding: 20px 15px !important;
	}

}

@media screen and (max-width:992px) and (min-width:768px) {
	.top-bar {
		padding: 20px 15px !important;
	}

	.left-section {
		width: 32%;

	}

	.right-section {
		width: 68%;
	}

}

@media screen and (max-width:767px) {
	.top-bar {
		padding: 20px 15px !important;
	}

	.navbar-toggle {
		float: left;
	}

	.navbar-collapse {
		padding-right: 15px;
		padding-left: 15px;
	}

	.left-section {
		width: auto;
		min-height: auto !important;
		height: auto;

	}

	.right-section {
		width: auto;
	}

	.develop {
		display: none;
	}

	.main-section {
		padding: 15px;
	}

	.headn-h5 {
		margin-top: 25px;
	}

}

/* end here */